import ProductInput from "@components/ProductInput";
import styles from "./styles.module.scss";
import { useNavigate } from "react-router-dom";
import { ArrowLeft, Download } from "lucide-react";

const Header = () => {
  const navigate = useNavigate();

  return (
    <div className={styles.header}>
      <div className={styles.back}>
        <button className={styles.back__button} onClick={() => navigate(-1)}>
          <ArrowLeft size={20} color="#5295E0" />
        </button>
        <h2 className={styles.back__title}>CRO Canvas</h2>
      </div>
      <div className={styles.right}>
        <div className={styles.right__item}>
          <div className={styles.select}>
            <ProductInput label={""} />
          </div>
        </div>
        <div className={styles.right__item}>
          <button
            className={
              "button button--sm button--gap button--m-0 button--spacing"
            }
          >
            <Download size={16} color="#fff" style={{ margin: 0 }} />
            Save
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
