import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styles from "./styles.module.scss";
import newStyles from "./new-styles.module.scss";
import { ROUTES } from "src/router/routes";
import ImageSection from "./сomponents/ImageSection";
import OptimisationPageWrapper from "../../../components/OptimisationPageWrapper";
import Save from "@assets/icons/save.svg";
import CloseIcon from "@components/CloseIcon";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import UpdatePhotoWidget from "./сomponents/UpdatePhotoWidget";
import PromptWidget from "./сomponents/PromptWidget";
import { toDataURL } from "src/utils/toDataURL";
import { getMaskAndPromptSuggestions, generateImage } from "./api";
import classNames from "classnames";
import SearchWidget from "./сomponents/SearchWidget";
import Templates from "./сomponents/Templates";
import { Template } from "./сomponents/Templates/Template";
import { assetsListImages, listImages, templatesMock } from "./mock";
import asinSvg from "./images/asin.svg";
import generateSvg from "./images/generate.svg";
import assetsSvg from "./images/assets.svg";
import settingsSvg from "./images/settings.svg";
import selectedImage from "./images/selected.png";
import Header from "./сomponents/Header";
import { Minus, Plus, Ruler, Search, Upload } from "lucide-react";
import { Select } from "@components/Select";
import reference1 from "./images/reference-1.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import SwiperCore from "swiper";
import image2_2_full from "./images/2-2-full.png";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./swiper.scss";

SwiperCore.use([Navigation]);

const PhotoOptimisationDetails: React.FC = () => {
  const [openedImages, setOpenedImages] = useState<
    { img: string; id: string; zoom: string }[]
  >([]);
  const [zoom, setZoom] = useState(1);
  const [promptData, setPromptData] = useState("");
  const [showPrompt, setShowPrompt] = useState(false);
  const [suggestions1, setSuggestions] = useState<string[]>([]);
  const [maskResult, setMaskResult] = useState<string[]>([]);
  const [templates, setTemplates] = useState<Template[]>([]);
  const [generatedImage, setGeneratedImage] = useState("");
  const [reset, setReset] = useState(false);
  const [imageName, setImageName] = useState("");
  const [uploadedImage, setUploadedImage] = useState("");
  const [activeMask, setActiveMask] = useState(0);
  const [search, setSearch] = useState("");
  const [activeImageId, setActiveImageId] = useState<string | null>(null);
  const changeActiveMask = (index: number) => {
    setActiveMask(Math.max(0, Math.min(maskResult.length - 1, index)));
  };

  const location = useLocation();
  const nav = useNavigate();

  const { imageUrl } = location.state || {};
  const currentImageUrl = uploadedImage || imageUrl;

  const id = location.pathname.split("/").pop() || "";

  useEffect(() => {
    if (imageUrl && id) {
      setOpenedImages((prevImages) => {
        const existingImage = prevImages.find(
          (imgObj) => imgObj.img === imageUrl,
        );
        if (existingImage) {
          const updatedImages = prevImages.map((imgObj) =>
            imgObj.img === imageUrl
              ? { ...imgObj, zoom: zoom.toFixed(2) }
              : imgObj,
          );
          localStorage.setItem("openedImages", JSON.stringify(updatedImages));
          return updatedImages;
        } else {
          const updatedImages = [
            ...prevImages,
            { img: imageUrl, id, zoom: zoom.toFixed(2) },
          ];
          localStorage.setItem("openedImages", JSON.stringify(updatedImages));
          return updatedImages;
        }
      });
    }
  }, [imageUrl, id, zoom]);

  const handleRedirectToLayout = (imageId: string) => {
    const storedImages = localStorage.getItem("openedImages");
    if (storedImages) {
      const parsedImages = JSON.parse(storedImages);
      const nextImage = parsedImages.find((imgObj) => imgObj.id === imageId);
      const nextScale = nextImage ? parseFloat(nextImage.zoom) : 1;
      setZoom(nextScale);
    } else {
      setZoom(1);
    }
    setActiveImageId(imageId);
    nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/${imageId}`);
  };

  const handleRemove = (imgToRemove: string) => {
    setOpenedImages((prevImages) => {
      const updatedImages = prevImages.filter(
        (imgObj) => imgObj.img !== imgToRemove,
      );
      localStorage.setItem("openedImages", JSON.stringify(updatedImages));

      if (updatedImages.length > 0) {
        const nextImageId = updatedImages[0].id;
        const nextImageZoom = updatedImages[0].zoom;

        setZoom(Number(nextImageZoom));
        nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/${nextImageId}`);
      } else {
        setZoom(1);
        nav(`/${ROUTES.CRO}/${ROUTES.CRO_PHOTOUPDATE}/`);
      }

      return updatedImages;
    });
  };

  const changeZoom = (newZoom: number) => Math.min(Math.max(newZoom, 0.2), 2);

  useEffect(() => {
    if (canvasRef.current === null) {
      return;
    }

    const handleWheel = (e: WheelEvent) => {
      if (e.ctrlKey) {
        e.preventDefault();
        setZoom((prevZoom) => {
          const delta = -e.deltaY * 0.0005;
          const newZoom = prevZoom + delta;
          return changeZoom(newZoom);
        });
      }
    };

    canvasRef.current.addEventListener("wheel", handleWheel, {
      passive: false,
    });

    return () => {
      canvasRef.current.removeEventListener("wheel", handleWheel);
    };
  }, []);

  const handleZoomIn = () => setZoom((prevZoom) => changeZoom(prevZoom + 0.05));

  const handleZoomOut = () =>
    setZoom((prevZoom) => changeZoom(prevZoom - 0.05));

  const [loadingMaskAndPropmtSuggestions, setLoadingMaskAndPropmtSuggestions] =
    useState(false);
  const [loadingImageGeneration, setLoadingImageGeneration] = useState(false);
  const [base64data, setBase64data] = useState(null);

  const onStartGeneration = async () => {
    setMaskResult([]);
    setSuggestions([]);
    setLoadingMaskAndPropmtSuggestions(true);
    const imageDataUrl = await toDataURL(currentImageUrl);
    setBase64data(imageDataUrl);

    const [masks, suggestions] =
      await getMaskAndPromptSuggestions(imageDataUrl);

    setSuggestions(Array.isArray(suggestions) ? suggestions : []);
    setMaskResult(Array.isArray(masks) ? masks : []);
    setTemplates(templatesMock);
    setLoadingMaskAndPropmtSuggestions(false);
    setShowPrompt(true);
    setReset(false);
  };

  const onUploadImage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const [file] = event.target.files;

    const hasImage = Boolean(file);

    if (!hasImage) {
      setImageName("");
      setUploadedImage("");
      return;
    }

    setImageName(file.name);
    setUploadedImage(URL.createObjectURL(file));

    setGeneratedImage("");

    if (showPrompt) {
      onStartGeneration();
    }
  };

  const onGenerateImage = async () => {
    const mask = maskResult[activeMask];
    const initImages = [base64data];

    if (!base64data || maskResult.length === 0 || !mask) {
      return;
    }

    setLoadingImageGeneration(true);
    setGeneratedImage("");
    setShowPrompt(false);

    const image = await generateImage(initImages, mask);

    setLoadingImageGeneration(false);
    setGeneratedImage(image);
    setMaskResult([]);
    setReset(false);
  };

  const [activeNavIndex, setActiveNavIndex] = useState(0);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const canvasRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={classNames(newStyles.wrapper, "hello")}>
        <Header />
        <div className={newStyles.main}>
          <div className={newStyles.sidebar}>
            <div className={newStyles.sidebar__primary}>
              <ul className={newStyles.nav}>
                {[
                  {
                    label: "ASIN",
                    icon: asinSvg,
                  },
                  {
                    label: "Generate",
                    icon: generateSvg,
                  },
                  {
                    label: "Assets",
                    icon: assetsSvg,
                  },
                  {
                    label: "Settings",
                    icon: settingsSvg,
                  },
                ].map((widget, i) => {
                  return (
                    <li
                      key={i}
                      className={classNames(
                        newStyles.nav__item,
                        i === activeNavIndex && newStyles["nav__item--active"],
                      )}
                      onClick={() => setActiveNavIndex(i)}
                    >
                      {<img src={widget.icon} alt="" />}
                      <span>{widget.label}</span>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className={newStyles.sidebar__secondary}>
              {(activeNavIndex === 0 || activeNavIndex === 2) && (
                <label
                  className={classNames(
                    "button button--sm button--m-0 button--gap button--outline button--m-0",
                  )}
                >
                  <input
                    type="file"
                    onChange={onUploadImage}
                    accept="image/png, image/gif, image/jpeg"
                    hidden
                  />
                  {imageName ? (
                    <span className={styles.buttonText}>{imageName}</span>
                  ) : (
                    <>
                      <Upload size={16} /> upload new
                    </>
                  )}
                </label>
              )}
              {activeNavIndex === 0 && (
                <>
                  <ul
                    className={classNames(
                      newStyles.assets,
                      newStyles["assets--grid"],
                    )}
                  >
                    {listImages.map((image, i) => {
                      return (
                        <li
                          key={i}
                          className={classNames(
                            newStyles.image,
                            i === activeImageIndex &&
                              newStyles["image--active"],
                          )}
                          style={{ backgroundImage: `url(${image.thumb})` }}
                          onClick={() => setActiveImageIndex(i)}
                        ></li>
                      );
                    })}
                  </ul>

                  <button
                    hidden
                    className={"button button--sm"}
                    onClick={() => setActiveImageIndex(-1)}
                  >
                    reset
                  </button>
                </>
              )}

              {activeNavIndex === 1 && (
                <div className={newStyles.tabs}>
                  <div className={newStyles.tabs__nav}>
                    {["Templates", " Prompt Builder"].map((text, i) => (
                      <button
                        key={i}
                        className={classNames(
                          newStyles.tabs__button,
                          i === activeTabIndex && newStyles.active,
                        )}
                        onClick={() => setActiveTabIndex(i)}
                      >
                        {text}
                      </button>
                    ))}
                  </div>
                  {activeTabIndex === 0 && (
                    <>
                      <Templates loading={false} templates={templatesMock} />
                    </>
                  )}
                  {activeTabIndex === 1 && (
                    <>
                      <div className={classNames(newStyles.inputs)}>
                        <textarea
                          className={newStyles.textarea}
                          value="A bottle of Sugarbear PRO hair vitamins standing on bathroom counter in front of soft shadows"
                        ></textarea>
                        <div className="input input--row">
                          <div className="input__label">Reference Image</div>
                          <div className="input__buttons">
                            <button
                              className="input__button"
                              style={{ backgroundImage: `url(${reference1})` }}
                            ></button>
                            <button className="input__button">
                              <svg
                                width="22"
                                height="22"
                                viewBox="0 0 22 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M3.03195 0C1.37078 0 0.00390625 1.36689 0.00390625 3.02806V5.81256C0.00390625 7.47373 1.37078 8.83247 3.03195 8.83247H7.97586C9.63703 8.83247 11.0039 7.47373 11.0039 5.81256V3.02806C11.0039 1.36689 9.63703 0 7.97586 0H3.03195ZM3.03195 1.64799H7.97586C8.75169 1.64799 9.35594 2.25222 9.35594 3.02806V5.81256C9.35594 6.5884 8.75169 7.18452 7.97586 7.18452H3.03195C2.25612 7.18452 1.65187 6.5884 1.65187 5.81256V3.02806C1.65187 2.25222 2.25612 1.64799 3.03195 1.64799Z"
                                  fill="#7C7C78"
                                />
                                <path
                                  d="M14.0238 13.1675C12.3627 13.1675 11.0039 14.5262 11.0039 16.1874V18.9719C11.0039 20.6331 12.3627 22 14.0238 22H18.9759C20.637 22 21.9958 20.6331 21.9958 18.9719V16.1874C21.9958 14.5262 20.637 13.1675 18.9759 13.1675H14.0238ZM14.0238 14.8155H18.9759C19.7517 14.8155 20.3478 15.4116 20.3478 16.1874V18.9719C20.3478 19.7477 19.7517 20.3439 18.9759 20.3439H14.0238C13.248 20.3439 12.6519 19.7477 12.6519 18.9719V16.1874C12.6519 15.4116 13.248 14.8155 14.0238 14.8155Z"
                                  fill="#7C7C78"
                                />
                                <path
                                  d="M13.9246 9.10725L16.1571 11.3397C16.4763 11.6606 16.9943 11.6643 17.318 11.348L19.5505 9.14802C19.8766 8.82798 19.8803 8.30372 19.5588 7.97902C19.2396 7.65814 18.7216 7.6544 18.3979 7.97071L16.7418 9.59435L15.0857 7.93825C14.7643 7.61986 14.2463 7.61986 13.9236 7.94024C13.6008 8.26071 13.6008 8.78488 13.9246 9.10725Z"
                                  fill="#7C7C78"
                                />
                                <path
                                  d="M13.3024 3.59644C13.0853 3.59771 12.8775 3.68451 12.724 3.83799C12.5705 3.99147 12.4837 4.19928 12.4824 4.41634C12.4816 4.63481 12.5674 4.8447 12.7211 4.99994C12.8749 5.15519 13.0839 5.2431 13.3024 5.24439H15.9164V9.05989C15.9177 9.27836 16.0056 9.4874 16.1608 9.64112C16.3161 9.79485 16.5259 9.8807 16.7444 9.87984C16.9615 9.87856 17.1693 9.79176 17.3228 9.63826C17.4763 9.48477 17.5631 9.27696 17.5643 9.05989V4.41634C17.563 4.19929 17.4762 3.9915 17.3228 3.83802C17.1693 3.68454 16.9615 3.59774 16.7444 3.59644H13.3024Z"
                                  fill="#7C7C78"
                                />
                                <path
                                  d="M2.41502 12.8353C2.0889 13.1554 2.08516 13.6796 2.40671 14.0043C2.72679 14.3306 3.25102 14.3343 3.57571 14.0126L5.22368 12.389L6.87977 14.037C7.20214 14.3608 7.72638 14.3608 8.04877 14.037C8.3672 13.7157 8.3672 13.1975 8.04877 12.8761L5.80818 10.6436C5.48901 10.3227 4.97105 10.319 4.80927 10.4774L2.41502 12.8353Z"
                                  fill="#7C7C78"
                                />
                                <path
                                  d="M5.23235 12.0962C5.12349 12.0958 5.01562 12.1169 4.91496 12.1584C4.8143 12.1998 4.72285 12.2608 4.64587 12.3378C4.5689 12.4147 4.50792 12.5062 4.46646 12.6069C4.425 12.7075 4.40387 12.8154 4.4043 12.9242V17.5597C4.40388 17.6685 4.42502 17.7764 4.46648 17.877C4.50795 17.9777 4.56892 18.0691 4.6459 18.1461C4.72287 18.2231 4.81432 18.2841 4.91497 18.3255C5.01563 18.367 5.12349 18.3881 5.23235 18.3877H8.66629C8.77515 18.3881 8.88302 18.367 8.98368 18.3255C9.08434 18.2841 9.17579 18.2231 9.25277 18.1461C9.32975 18.0692 9.39072 17.9777 9.43218 17.877C9.47364 17.7764 9.49477 17.6685 9.49434 17.5597C9.49304 17.3412 9.40513 17.1322 9.24989 16.9784C9.09464 16.8247 8.88476 16.7389 8.66629 16.7397H6.05227V12.9242C6.05314 12.7058 5.96729 12.4959 5.81357 12.3406C5.65985 12.1854 5.45082 12.0975 5.23235 12.0962Z"
                                  fill="#7C7C78"
                                />
                              </svg>
                            </button>
                            <button className="input__button">
                              <svg
                                width="18"
                                height="22"
                                viewBox="0 0 18 22"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.71429 19.5001C1.71429 20.1442 1.97015 20.7619 2.4256 21.2174C2.88104 21.6728 3.49876 21.9287 4.14286 21.9287H13.8571C14.5012 21.9287 15.119 21.6728 15.5744 21.2174C16.0298 20.7619 16.2857 20.1442 16.2857 19.5001V4.92868H1.71429V19.5001ZM4.14286 7.35725H13.8571V19.5001H4.14286V7.35725ZM13.25 1.28582L12.0357 0.0715332H5.96429L4.75 1.28582H0.5V3.71439H17.5V1.28582H13.25Z"
                                  fill="#7C7C78"
                                />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <label className="input input--sm">
                          <div className="input__label">Product</div>
                          <input
                            className="input__input"
                            type="text"
                            value="a bottle of Sugarbear PRO hair vitamins"
                          />
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Placement</div>
                          <div className="input__row">
                            <ControlledSelect placeholder={"standing on"} />
                            <input
                              className="input__input"
                              type="text"
                              value="bathroom counter"
                            />
                          </div>
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Background</div>
                          <div className="input__row">
                            <ControlledSelect placeholder="in front of" />
                            <input
                              className="input__input"
                              type="text"
                              value="soft shadows"
                            />
                          </div>
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Number of resuts</div>
                          <div className="input__row">
                            <ControlledSelect placeholder="1" />
                          </div>
                        </label>
                        <label className={newStyles.checkbox}>
                          <input
                            type="checkbox"
                            name="template"
                            value={"label"}
                            className={newStyles.checkbox__input}
                            checked={false}
                            onChange={(e) => {
                              // for test
                              const value = e.target.checked;
                              setTimeout(() => {
                                e.target.checked = value;
                              });
                            }}
                          />
                          <span className={newStyles.checkbox__label}>
                            Correct Color
                          </span>
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Render strength</div>
                          <div className="input__row">
                            <ControlledSelect placeholder="Default" />
                          </div>
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Color strength</div>
                          <div className="input__row">
                            <ControlledSelect placeholder="Strong" />
                          </div>
                        </label>
                        <label className="input input--sm">
                          <div className="input__label">Outline Strength</div>
                          <div className="input__row">
                            <ControlledSelect placeholder="Weak" />
                          </div>
                        </label>
                      </div>

                      <button className={"button button--sm button--m-0"}>
                        Generate image
                      </button>
                    </>
                  )}
                </div>
              )}

              {activeNavIndex === 2 && (
                <>
                  <label className="input">
                    <div className="input__icon-wrapper">
                      <Search
                        size={15}
                        stroke="#A6A7A8"
                        className="input__icon"
                      />
                      <input
                        className="input__input"
                        type="text"
                        placeholder="Search assets..."
                      />
                    </div>
                  </label>
                  {assetsListImages.map(({ label, list }, i) => {
                    return (
                      <div className={newStyles.slider}>
                        <div className={newStyles.slider__header}>
                          <h4 className={newStyles.slider__label}>{label}</h4>
                          <button className={newStyles.slider__link}>
                            Show more
                          </button>
                        </div>
                        <Swiper
                          watchSlidesProgress={true}
                          loop
                          navigation
                          spaceBetween={8}
                          slidesPerView={3}
                        >
                          {list.map((image, i) => (
                            <SwiperSlide key={i}>
                              {
                                <div
                                  className={newStyles.image}
                                  style={{
                                    backgroundImage: `url(${image})`,
                                  }}
                                ></div>
                              }
                            </SwiperSlide>
                          ))}
                        </Swiper>
                      </div>
                    );
                  })}
                </>
              )}

              {activeNavIndex === 3 && (
                <>
                  <ul className={newStyles.nav}>
                    {[
                      {
                        label: "Photo editor",
                        icon: asinSvg,
                      },
                      {
                        label: "Replace background",
                        icon: generateSvg,
                      },
                      {
                        label: "AI eraser",
                        icon: assetsSvg,
                      },
                      {
                        label: "Upscale photo",
                        icon: settingsSvg,
                      },
                    ].map((widget, i) => {
                      return (
                        <li
                          key={i}
                          className={classNames(newStyles.nav__item)}
                          // onClick={() => setActiveNavIndex(i)}
                        >
                          {<img src={widget.icon} alt="" />}
                          <span>{widget.label}</span>
                        </li>
                      );
                    })}
                  </ul>
                </>
              )}
            </div>
          </div>
          <div className={newStyles.canvas} ref={canvasRef}>
            <div className={newStyles.zoom}>
              <button
                className={newStyles.zoom__button}
                onClick={handleZoomOut}
              >
                <Minus size={12} />
              </button>
              <span>{Math.round(zoom * 100)}%</span>
              <button className={newStyles.zoom__button} onClick={handleZoomIn}>
                <Plus size={12} />
              </button>
            </div>

            <div
              className={newStyles.canvas__inner}
              style={{
                transform: `scale3D(${zoom}, ${zoom}, 1)`,
                transformOrigin: "0 0 0",
                transition: "transform 0.1s",
              }}
            >
              <div className={newStyles.images}>
                <div
                  className={classNames(
                    newStyles.images__item,
                    newStyles["images__item--workspace"],
                  )}
                >
                  <div className={newStyles.images__label}>
                    Workspace · drop your images & assets here
                  </div>
                  <div className={newStyles.images__box}>
                    <div
                      className={classNames(
                        newStyles.images__wrapper,
                        activeImageIndex !== -1 && newStyles.active,
                      )}
                    >
                      <div
                        className={classNames(
                          newStyles.resize,
                          activeNavIndex === 0 && newStyles.active,
                        )}
                      >
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                      </div>
                      {listImages.map((image, i) => {
                        return (
                          <img
                            src={image.full}
                            className={classNames(
                              newStyles.images__image,
                              i === activeImageIndex && newStyles.active,
                            )}
                            alt=""
                          />
                        );
                      })}
                    </div>
                  </div>

                  {activeNavIndex === 2 && (
                    <div className={newStyles.draggableImage}>
                      <div
                        className={newStyles.image}
                        style={{ backgroundImage: `url(${image2_2_full})` }}
                      ></div>
                      <div
                        className={classNames(
                          newStyles.resize,
                          newStyles.active,
                        )}
                      >
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__dot}></div>
                        <div className={newStyles.resize__buttons}>
                          <button className={newStyles.resize__button}>
                            Move to front
                          </button>
                          <button className={newStyles.resize__button}>
                            Move to back
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={classNames(
                    newStyles.images__item,
                    newStyles["images__item--result"],
                  )}
                >
                  <div className={newStyles.images__label}>Result</div>
                  <div className={newStyles.images__box}>
                    <img src={selectedImage} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div hidden>
        <OptimisationPageWrapper>
          <div className={styles.rowItems}>
            {openedImages.map((imgObj, index) => (
              <button
                key={index}
                // className={classNames(styles.card, {
                //   [styles.active]: activeImageId === imgObj.id || openedImages.length === 1,
                // })}
                className={classNames(styles.card, {
                  [styles.active]:
                    activeImageId === imgObj.id || openedImages.length === 1,
                })}
                onClick={() => handleRedirectToLayout(imgObj.id)}
              >
                {imgObj.img.split("/").pop()}
                <CloseIcon onClick={() => handleRemove(imgObj.img)} />
              </button>
            ))}
            <div className={styles.controls}>
              <label className={classNames(styles.button, styles.upload)}>
                <input
                  type="file"
                  onChange={onUploadImage}
                  accept="image/png, image/gif, image/jpeg"
                  hidden
                />
                {imageName ? (
                  <span className={styles.buttonText}>{imageName}</span>
                ) : (
                  <Upload />
                )}
              </label>
              <button className={styles.button}>
                <img src={Save} alt="save" />
                Save changes
              </button>
            </div>
          </div>
          <div>
            <DragDropContext onDragEnd={() => {}}>
              <Droppable droppableId="widgets">
                {(provided) => (
                  <div
                    className={styles.canvaContainer}
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    <div className={styles.sidebar}>
                      {/* SEARCH WIDGET */}
                      <SearchWidget
                        label="Add elements"
                        initioalValue={search}
                        onChange={setSearch}
                      />
                      <div className={styles.widgets}>
                        {provided.placeholder}
                      </div>
                    </div>
                    <div
                      className={styles.canva}
                      style={{
                        transform: `scale(${zoom})`,
                        transformOrigin: "center",
                      }}
                    >
                      <div className={styles.zoomBlock}>
                        <button
                          className={classNames(styles.card, styles.zoom)}
                          onClick={handleZoomIn}
                        >
                          +
                        </button>
                        <span>{Math.round(zoom * 100)}%</span>
                        <button
                          className={classNames(styles.card, styles.zoom)}
                          onClick={handleZoomOut}
                        >
                          -
                        </button>
                      </div>
                      <div className={styles.imageRow}>
                        <ImageSection
                          loading={loadingImageGeneration}
                          generatedImage={generatedImage}
                          masks={maskResult}
                          reset={reset}
                          imageUrl={
                            currentImageUrl ||
                            openedImages.find((imgObj) => imgObj.id === id)
                              ?.img ||
                            ""
                          }
                          changeMask={changeActiveMask}
                          activeMask={activeMask}
                        />
                        {(showPrompt || loadingMaskAndPropmtSuggestions) && (
                          <PromptWidget
                            loading={loadingMaskAndPropmtSuggestions}
                            setData={setPromptData}
                            data={suggestions1}
                            setReset={setReset}
                            onGenerateImage={onGenerateImage}
                          />
                        )}
                      </div>

                      {(showPrompt || loadingMaskAndPropmtSuggestions) && (
                        <Templates
                          loading={loadingMaskAndPropmtSuggestions}
                          templates={templates}
                        />
                      )}

                      {/* {widgets.find(widget => widget.id === "1" && widget.show) && <FormSection />}
                {widgets.find(widget => widget.id === "2" && widget.show) && 
                  <OptimizationScore ctrChange={ctrChange} salesChange={salesChange} optimizationScore={optimizationScore} />}
                {widgets.find(widget => widget.id === "3" && widget.show) && <EditorWidget />}
                {widgets.find(widget => widget.id === "4" && widget.show) && 
                   <ReplaceBgWidget imageUrl={imageUrl || openedImages.find(imgObj => imgObj.id === id)?.img || ''} />}
                {widgets.find(widget => widget.id === "5" && widget.show) && <AIWidget />}
                {widgets.find(widget => widget.id === "6" && widget.show) && <AIEraserWidget />}
                {widgets.find(widget => widget.id === "7" && widget.show) && <HistoryWidget />} */}

                      <UpdatePhotoWidget
                        onStartGeneration={onStartGeneration}
                      />
                    </div>
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </OptimisationPageWrapper>
      </div>
    </>
  );
};

export default PhotoOptimisationDetails;

const ControlledSelect = ({ placeholder }: { placeholder: string }) => {
  const [value, setValue] = useState("");

  return (
    <Select
      value={value}
      placeholder={placeholder}
      options={["value 1", "value 2"]}
      onChange={(val) => setValue(val)}
    />
  );
};
