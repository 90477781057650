import { useTitle } from "@hooks/useTitle";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styles from "./styles.module.scss";
import { SpiderService } from "@services/spider/spider.services";
import { useProductStore } from "src/store/overviewProduct.state";
import { useInsightsStore } from "src/store/insights/insights.state";
import { formatDateRange } from "src/utils/formatDate";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { formattedKeys } from "src/utils/convertUtil";
import classNames from "classnames";
import CalendarImage from "@assets/icons/calendar.svg";
import { CalendarModal } from "@components/calendar-modal";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { useWidgets } from "@hooks/useWidgets";
import PageWrapper from "@components/PageWrapper/PageWrapper";
import SubscriptionOwn from "./SubscriptionPerfomance";
import WidgetsSubscription, { Widget } from "./WidgetsSubscription";
import {
  getData,
  transformDataToObject,
} from "@pages/Dashboard/mocks/mockMetricsData";
import { initialChecks } from "@pages/Dashboard/features/SalesPerformance/types";
import { COLORS } from "@pages/Dashboard/features/SalesPerformance";
import Spin from "antd/lib/spin";
import { Chart } from "./SubscriptionChart";
import SubscriptionCountChart from "./SubscriptionCount";
import Tabs from "./Tabs";
import ProductCard from "@pages/CROModule/components/Product/ProductCard";
import product1 from "@assets/img/noImage.jpg";

export const SubscriptionPerformancePage: React.FC = () => {
  const { t } = useTranslation();
  useTitle(t("window.dashboard"));

  const { dateRange, setDateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const { updatedWidgets } = useWidgets();
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const [isDataLoading, setIsDataLoading] = useState(false);
  const [data, setData] = useState<any>();
  const [checks, setChecks] = useState(initialChecks);
  const [metricsColors, setMetricsColors] = useState<{
    [key: string]: string;
  }>({});

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
  };
  const { selectedProduct } = useProductStore();

  const img = selectedProduct?.image_link || product1;
  const price = selectedProduct?.price ? Number(selectedProduct.price) : 0;
  const name = selectedProduct?.item_name || "";

  const { setProductOneSpiderTitles, setProductOneSpiderValues } =
    useInsightsStore();

  useEffect(() => {
    const fetchProducts = async () => {
      if (!selectedProduct) return;

      try {
        const spider = new SpiderService();
        const spiderDots = await spider.getSpiderInfo(selectedProduct.asin);

        setProductOneSpiderTitles(formattedKeys(spiderDots));
        setProductOneSpiderValues(
          Object.values(spiderDots).map((value) =>
            parseFloat(value.toFixed(1)),
          ),
        );
      } catch (err) {
        console.error("Error fetching product:", err);
      }
    };

    fetchProducts();
  }, [selectedProduct]);
  useEffect(() => {
    const fetchData = async () => {
      setIsDataLoading(true);
      const data = await getData(dateRange, checks);
      const transformedData = transformDataToObject(data);
      setData(transformedData);
      setIsDataLoading(false);
    };

    if (Object.values(checks).some((value) => value)) {
      fetchData();
    }
  }, [dateRange, checks]);

  useEffect(() => {
    const colorMapping: { [key: string]: string } = {};
    Object.keys(checks).forEach((check, index) => {
      colorMapping[check] = COLORS[index % COLORS.length];
    });
    setMetricsColors(colorMapping);
  }, [checks]);

  const transformedWidgets: Widget[] = updatedWidgets.map((widget) => ({
    id: widget.id,
    items: widget.items || [],
  }));

  return (
    <PageWrapper>
      <>
        <div className={styles.header}>
          <div className={styles.title__container}>
            <h1 className={styles.title}>Subscription Performance</h1>
          </div>
          <div className={styles.buttons}>
            <div className={styles.calendar}>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  setIsCalendarOpen(!isCalendarOpen);
                }}
                className={classNames(
                  styles.button,
                  isCalendarOpen && styles.active,
                )}
              >
                <img src={CalendarImage} alt="Calendar" />
                {selectedDateRange}
              </button>
              {isCalendarOpen && (
                <div className={styles.modal}>
                  <CalendarModal
                    buttonWidth={148}
                    closeModal={() => setIsCalendarOpen(false)}
                    onDateChange={handleDateChange}
                    initialRange={dateRange}
                  />
                </div>
              )}
            </div>
            <CustomSelect dateRange={dateRange} />
          </div>
        </div>
      </>
      <div>
        <h3 className={styles.titleProduct}>Product</h3>
        <SubscriptionOwn
          selectedProduct={selectedProduct}
          selectType="your"
          type="my-product"
          className={styles.fullWidth}
        />
      </div>
      <div className={styles.blockBetween}>
        <ProductCard price={price} image={img} title={name} />

        <WidgetsSubscription widgets={transformedWidgets} />
      </div>
      <div className={styles.countBetween}>
        {!isDataLoading ? (
          <Chart checks={checks} metricsColors={metricsColors} data={data} />
        ) : (
          <div className={"sales-performance__loader"}>
            <Spin size="large" />
          </div>
        )}
        <SubscriptionCountChart />
      </div>
      <Tabs />
    </PageWrapper>
  );
};
