import classNames from "classnames";
import styles from "./styles.module.scss";
import SectionContainer from "../components/SectionContainer";
import { formatName, getValueWithLabel } from "./utils";
import { MetricCard } from "./MetricCard";
import React, { useEffect, useState } from "react";
import { ChevronDown } from "lucide-react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import { useWidgets } from "@hooks/useWidgets";
import { MAX_SELECTED_METRIC_BUILDERS } from "../../../constants";
import { IWidget } from "@pages/Dashboard/types/dashboard.types";
import { ConfigModal } from "@components/config-modal";
import { useSectionStore } from "src/store/croModule.state";
import { useOutsideClick } from "src/utils/useOutsideClick";

interface MetricBuilderProps {
  customTitle?: string;
}

const MetricBuilder = ({ customTitle }: MetricBuilderProps) => {
  const { isWhatIf } = useSectionStore((state) => ({
    isWhatIf: state.isWhatIf,
  }));
  const { handleApply, updatedWidgets } = useWidgets();
  const { setWidgets, widgets } = useDashboardStore((state) => ({
    setWidgets: state.setWidgets,
    widgets: state.widgets,
  }));
  const [widgetList, setWidgetList] = useState<IWidget[]>([]);
  const [isOpenTab, setIsOpenTab] = useState<boolean>(false);
  const [isTabOnTop] = useState<boolean>(false);
  const buttonRef = useOutsideClick(() => {
    setIsOpenTab(false);
  }, "mouseup");

  useEffect(() => {
    const selectedWidgets = updatedWidgets.slice(
      0,
      MAX_SELECTED_METRIC_BUILDERS,
    );
    setWidgetList(selectedWidgets);
    setWidgets(selectedWidgets);
  }, [updatedWidgets]);

  // const handleClickTab = () => {
  //   if (buttonRef.current) {
  //     const bottom = buttonRef.current.getBoundingClientRect().bottom;

  //     if (window.innerHeight - bottom < 800) {
  //       setIsTabOnTop(true);
  //     } else {
  //       setIsTabOnTop(false);
  //     }

  //     setIsOpenTab(!isOpenTab);
  //   }
  // };

  return (
    <SectionContainer>
      {/* <TopSectionIcons
        handleRemove={handleRemove}
        isContentClosed={false}
        provided={provided}
      /> */}
      <div className={styles.filters}>
        <h3 className={styles.title}>{customTitle || "Metrics"}</h3>
        <div className={styles.selects}>
          {/* <Tab
            onApply={handleApply}
            maxSelectedWidgets={MAX_SELECTED_METRIC_BUILDERS}
            showTabIds={[0]}
            bgNearItem="var(--nyle-gray-20)"
          /> */}
          <div
            className={classNames(styles.selectBox, isTabOnTop && styles.onTop)}
          >
            <button
              onClick={() => {
                setIsOpenTab(!isOpenTab);
              }}
              className={classNames(
                styles.selectBoxLabel,
                isOpenTab && styles.active,
              )}
            >
              {widgetList.length || "12"} metrics selected{" "}
              <div className={styles.arrow}>
                <ChevronDown size={24} />
              </div>
            </button>
            {isOpenTab && (
              <div className={classNames(styles.config)} ref={buttonRef}>
                <ConfigModal
                  buttonWidth={100}
                  closeModal={() => setIsOpenTab(false)}
                  initialWidgets={widgets}
                  setWidgets={setWidgets}
                  onApply={handleApply}
                  maxSelectedWidgets={12}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className={styles.metricsGrid}>
        {widgetList.map((metric, i) => {
          const profit = metric.difference.startsWith("+");
          const color = profit ? "#80C67A" : "#FE5858";

          if (!isWhatIf) {
            return (
              <div className={styles.card}>
                <div className={styles.header}>
                  <span>{formatName(metric.name)}</span>
                  <span style={{ color }}>{metric.difference}%</span>
                </div>
                <span className={styles.value}>
                  {getValueWithLabel(metric.name, Number(metric.value))}
                </span>
              </div>
            );
          }
          return (
            <React.Fragment key={i}>
              <MetricCard metric={metric} />
              {i !== 0 && (i + 1) % 6 === 0 && (
                <div className={styles.metricsGridSeparator}></div>
              )}
            </React.Fragment>
          );
        })}
      </div>
    </SectionContainer>
  );
};

export default MetricBuilder;
