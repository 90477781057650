import { Product } from "@services/amazon/products/types";
import { create } from "zustand";

interface IProductState {
  selectedProduct: Product | null;
  selectedProductToCompare: Product[];
  setSelectedProduct: (product: Product) => void;
  setSelectedProductToCompare: (products: Product[]) => void;
  selectedProductToCompareMultiselect: (product: Product) => void;
}

const selectedProduct = {
  id: "1",
  displayName: "1",
  dt_open: "string",
  dt_updated: "string",
  title: "string",
  asin: "B07GWVP5RC",
  brand: "Zephyr",
  bullet_points: [
    "PACKED WITH FEATURES: The Presrv Dual Zone Wine & …ys ensures you have your favorite drinks on hand.",
    "COMPACT YET ROOMY: With convenient sizing and impr…nding unit with finished sides and chic exterior.",
    "WHISPER QUIET AMBIENCE: 3 LED light options create…and other beverages that are best when frostiest.",
    "DUAL TEMPERATURE ZONES: The Presrv French Door Win…esign at least as much as perfectly chilled wine.",
    "AWARD WINNING EXCELLENCE: Zephyr is an award winni…hat serves award winning moments, with every sip.",
  ],
  image_link: "https://m.media-amazon.com/images/I/61huunI-IpL.jpg",
  item_description: "",
  item_name:
    "Zephyr PRWB24C32BG PRWB24C32AG 24 Inch 5.18 Cubic Feet. Capacity Freestanding or Built in Full Size Beverage Center, in Stainless Steel",
  link: "https://www.amazon.com/dp/B07GWVP5RC",
  price: 1799,
};

export const useProductStore = create<IProductState>((set) => ({
  selectedProduct,
  selectedProductToCompare: [],
  setSelectedProduct: (product) => set({ selectedProduct: product }),
  setSelectedProductToCompare: (products) =>
    set((state) => ({
      selectedProductToCompare: Array.isArray(products) ? products : [products],
    })),

  selectedProductToCompareMultiselect: (product) =>
    set((state) => ({
      selectedProductToCompare: [
        ...state.selectedProductToCompare.filter(
          (p) => p.asin !== product.asin,
        ),
        product,
      ],
    })),
}));
