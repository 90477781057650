import { useEffect, useState } from "react";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state.ts";
import { useMathStore } from "@pages/Dashboard/store/math.state";
import { IWidget } from "@pages/Dashboard/types/dashboard.types.ts";
import { createWidgets } from "src/utils/createWidgets";
import { fetchWidgets } from "src/utils/fetchWidgets";

export const useWidgets = () => {
  const [updatedWidgets, setUpdatedWidgets] = useState<IWidget[]>([]);
  const { setWidgets, dateRange, setDateRange } = useDashboardStore(
    (state) => ({
      setWidgets: state.setWidgets,
      dateRange: state.dateRange,
      setDateRange: state.setDateRange,
    }),
  );

  useEffect(() => {
    const fetchInitialWidgets = async () => {
      await fetchWidgets(
        dateRange.startDate.toISOString().split("T")[0],
        dateRange.endDate.toISOString().split("T")[0],
      );
      const newWidgets = createWidgets(useMathStore.getState());

      setWidgets(newWidgets);
      setUpdatedWidgets(newWidgets);
    };
    fetchInitialWidgets();
  }, [setWidgets, dateRange]);

  const setAvailableWidgets = useDashboardStore(
    (state) => state.setAvailableWidgets,
  );
  const mathStoreData = useMathStore((state) => ({
    aovData: state.aovData,
    salesTotalData: state.salesTotalData,
    adsImpressionsData: state.adsImpressionsData,
    adsClicksData: state.adsClicksData,
    adsOrdersData: state.adsOrdersData,
    adsUnitsSoldData: state.adsUnitsSoldData,
    adsSalesData: state.adsSalesData,
    adsSpendData: state.adsSpendData,
    adsAcosData: state.adsAcosData,
    adsRoasData: state.adsRoasData,
    adsCpcData: state.adsCpcData,
    adsCvrData: state.adsCvrData,
    adsCtrData: state.adsCtrData,
    organicImpressionsData: state.organicImpressionsData,
    organicClicksData: state.organicClicksData,
    organicCartAddsData: state.organicCartAddsData,
    organicUnitsSoldData: state.organicUnitsSoldData,
    organicCvrData: state.organicCvrData,
    organicCtrData: state.organicCtrData,
  }));

  useEffect(() => {
    const newWidgets = createWidgets(mathStoreData);

    setAvailableWidgets(newWidgets);
    if (updatedWidgets.length === 0 || updatedWidgets) {
      setWidgets(newWidgets);
      setUpdatedWidgets(newWidgets);
    }
  }, [
    mathStoreData.aovData,
    mathStoreData.salesTotalData,
    mathStoreData.adsImpressionsData,
    mathStoreData.adsClicksData,
    mathStoreData.adsOrdersData,
    mathStoreData.adsUnitsSoldData,
    mathStoreData.adsSalesData,
    mathStoreData.adsSpendData,
    mathStoreData.adsAcosData,
    mathStoreData.adsRoasData,
    mathStoreData.adsCpcData,
    mathStoreData.adsCvrData,
    mathStoreData.adsCtrData,
    mathStoreData.organicImpressionsData,
    mathStoreData.organicClicksData,
    mathStoreData.organicCartAddsData,
    mathStoreData.organicUnitsSoldData,
    mathStoreData.organicCvrData,
    mathStoreData.organicCtrData,
    setWidgets,
    setAvailableWidgets,
  ]);

  const handleApply = (
    newWidgets: IWidget[],
    newDateRange?: { startDate: Date; endDate: Date },
  ) => {
    setUpdatedWidgets(newWidgets);
    setWidgets(newWidgets);
    newDateRange && setDateRange(newDateRange);
  };

  return {
    updatedWidgets,
    handleApply,
  };
};
