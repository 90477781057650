import { ArrowLeft, Download } from "lucide-react";
import styles from "./styles.module.scss";
import { Option, Select } from "@components/Select";
import { useEffect, useState } from "react";
import { CalendarModal } from "@components/calendar-modal";
import { useDashboardStore } from "@pages/Dashboard/store/dashboard.state";
import classNames from "classnames";
import { formatDateRange } from "src/utils/formatDate";
import CalendarImage from "@assets/icons/calendar.svg";
import CustomSelect from "@pages/AdsAnalitics/components/Header/ui/CustomSelect";
import { ProductsService } from "@services/amazon/products/products.service";
import { ProductWithImageLink } from "@services/amazon/products/types";
import { useCanvaStore } from "src/store/canva.state";
import { Input } from "@components/Input";

export const Header: React.FC = () => {
  const { dateRange, setDateRange } = useDashboardStore((state) => ({
    dateRange: state.dateRange,
    setDateRange: state.setDateRange,
  }));
  const { asin, setAsin } = useCanvaStore();
  const [searchAsin, setSearchAsin] = useState("");
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [asinOptions, setAsinOptions] = useState<Option[]>();
  const [selectedDateRange, setSelectedDateRange] = useState(
    formatDateRange(dateRange.startDate, dateRange.endDate),
  );
  const productsService = new ProductsService();

  const handleDateChange = (startDate, endDate) => {
    setDateRange({ startDate, endDate });
    setSelectedDateRange(formatDateRange(startDate, endDate));
    setIsCalendarOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await productsService.getOwnProducts({
          limit: 32,
        });
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };

    fetchData();
  }, [asin]);

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        const data = await productsService.searchOwnProducts({
          limit: 32,
          asin: searchAsin,
        });
        if (!data) return;
        const options = data.map((product: ProductWithImageLink) => ({
          id: product.asin,
          text: product.item_name,
          img: product.image_link,
          asin: product.asin,
        }));

        setAsinOptions(options);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchSearchData();
  }, [searchAsin]);

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <button className={styles.back}>
            <ArrowLeft size={20} color="#5295E0" />
          </button>
          <h2>New Canvas</h2>
        </div>
        <div className={styles.select__container}>
          <Select
            value={asin}
            onChange={(asin: string) => {
              setAsin(asin);
            }}
            placeholder="Select ASIN"
            options={asinOptions}
            className={styles.select}
          >
            <Input
              value={searchAsin}
              onChange={(value) => setSearchAsin(value)}
              placeholder="Search ASIN"
              className={styles.search}
            />
          </Select>
        </div>
        <div className={styles.buttons}>
          <div className={styles.calendar}>
            <button
              onClick={(e) => {
                e.stopPropagation();
                setIsCalendarOpen(!isCalendarOpen);
              }}
              className={classNames(
                styles.button,
                isCalendarOpen && styles.active,
              )}
            >
              <img src={CalendarImage} alt="Calendar" />
              {selectedDateRange}
            </button>
            {isCalendarOpen && (
              <div className={styles.modal}>
                <CalendarModal
                  buttonWidth={148}
                  closeModal={() => setIsCalendarOpen(false)}
                  onDateChange={handleDateChange}
                  initialRange={dateRange}
                />
              </div>
            )}
          </div>
          <CustomSelect dateRange={dateRange} className={styles.date__select} />
        </div>
        <button className={styles.save} onClick={() => {}}>
          <Download size={16} color="#fff" />
          <span>Save Changes</span>
        </button>
      </div>
    </div>
  );
};
