import styles from "./styles.module.scss";
import { FormEventHandler } from "react";
import TemplateItem, { Template } from "./Template";
import { Spin } from "antd";

interface SelectTemplateWidgetsProps {
  loading: boolean;
  templates: Template[];
}

const SelectTemplateWidgets = ({
  loading,
  templates,
}: SelectTemplateWidgetsProps) => {
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const target = event.nativeEvent.target;

    if (!(target instanceof HTMLFormElement)) {
      return;
    }

    const inputs = target.querySelectorAll(
      "input:checked",
    ) as NodeListOf<HTMLInputElement>;

    const checkedTemplates = Array.from(inputs).map((el) => el.value);

    if (checkedTemplates.length === 0) {
      return;
    }

    console.log(checkedTemplates);
  };

  return (
    <form onSubmit={handleSubmit} className={styles.block}>
      {loading ? (
        <div className={styles.block__loading}>
          <Spin size="large" />
        </div>
      ) : (
        <>
          <div className={styles.accordion}>
            {templates.map((template, i) => (
              <TemplateItem key={i} template={template} />
            ))}
          </div>
          <button className={"button button--sm button--m-0"}>
            Generate image
          </button>
        </>
      )}
    </form>
  );
};

export default SelectTemplateWidgets;
