import image1 from "./images/1.jpg";
import image1Full from "./images/1-full.png";
import image2 from "./images/2.jpg";
import image3 from "./images/3.jpg";
import image4 from "./images/4.jpg";
import image5 from "./images/5.jpg";
import image6 from "./images/6.jpg";
import image6Full from "./images/6-full.png";
import image7 from "./images/7.jpg";
import image1_1 from "./images/1-1.png";
import image1_2 from "./images/1-2.png";
import image1_3 from "./images/1-3.png";
import image2_1 from "./images/2-1.png";
import image2_2 from "./images/2-2.png";
import image2_3 from "./images/2-3.png";
import image3_1 from "./images/3-1.png";
import image3_2 from "./images/3-2.png";
import image3_3 from "./images/3-3.png";
import image4_1 from "./images/4-1.png";
import image4_2 from "./images/4-2.png";
import image4_3 from "./images/4-3.png";
import image5_1 from "./images/5-1.png";
import image5_2 from "./images/5-2.png";
import image5_3 from "./images/5-3.png";

export interface FormField {
  id: string;
  label: string;
  name: string;
}

export const formFields: FormField[] = [
  { id: "aspects", label: "Aspects", name: "aspects" },
  {
    id: "instructions",
    label: "Additional Instructions For AI Assistant",
    name: "instructions",
  },
  { id: "hypothesis", label: "Hypothesis Example", name: "hypothesis" },
  { id: "customerSay", label: "What Customers Say", name: "customerSay" },
];

export const widgets = [];

const list = [
  {
    label: "Width",
    value: "800",
  },
  {
    label: "Height",
    value: "800",
  },
  {
    label: "Denoising Strength",
    value: "0.95",
  },
  {
    label: "Sampler",
    value: "Euler a",
  },
  {
    label: "Scheduler",
    value: "Automatic",
  },
  {
    label: "Steps",
    value: "30",
  },
  {
    label: "CFG Scale",
    value: "7",
  },
  {
    label: "Negative Prompt",
    value: "",
  },
  {
    label: "Model",
    value: "scl_xl_base_1.0_impainting_0.1.safetensors",
  },
  {
    label: "Invert Mask",
    value: "Yes",
  },
];

export const templatesMock = [
  {
    label: "Template 1",
    list,
  },
  {
    label: "Template 2",
    list,
  },
  {
    label: "Template 3",
    list,
  },
  {
    label: "Template 4",
    list,
  },
  {
    label: "Template 5",
    list,
  },
  {
    label: "Template 6",
    list,
  },
  {
    label: "Template 7",
    list,
  },
];

export const listImages = [
  { thumb: image1, full: image1Full },
  { thumb: image2, full: image2 },
  { thumb: image3, full: image3 },
  { thumb: image4, full: image4 },
  { thumb: image5, full: image5 },
  { thumb: image6, full: image6Full },
  { thumb: image7, full: image6Full },
];

export const assetsListImages = [
  {
    label: "Platforms and displays",
    list: [image1_1, image1_2, image1_3, image1_3],
  },
  {
    label: "Plants and natural elements",
    list: [image2_1, image2_2, image2_3, image1_3],
  },
  {
    label: "Vases, Bowls, and Mugs",
    list: [image3_1, image3_2, image3_3, image3_3],
  },
  {
    label: "Home and interior",
    list: [image4_1, image4_2, image4_3, image4_3],
  },
  {
    label: "Fruits and vegetables",
    list: [image5_1, image5_2, image5_3, image5_3],
  },
];
