import { useOutsideClick } from "src/utils/useOutsideClick";
import styles from "./styles.module.scss";
import { useState } from "react";
import BottomArrow from "@assets/icons/bottomArrow";
import classNames from "classnames";
import { Checkbox } from "antd";

export type Option = {
  id: string;
  text: string;
  img?: string;
  asin?: string;
};

const renderOptions = (
  options: Option[] | string[] | React.ReactNode[],
  value: string | string[],
  onChange: (value: string | string[]) => void,
  isMultiSelect?: boolean,
) => {
  return options.map((option, i) => {
    if (option.img && option.id) {
      return (
        <button
          key={option.id}
          onClick={() => {
            if (isMultiSelect && Array.isArray(value)) {
              if (value.includes(option.id as string)) {
                onChange(value.filter((val) => val !== option.id));
              } else {
                onChange([...value, option.id]);
              }
            } else {
              onChange(option.id as string);
            }
          }}
        >
          {isMultiSelect && (
            <Checkbox checked={value.includes(option.id as string)} />
          )}
          <img src={option.img} alt="product" />
          <div className={styles.option__text}>
            <span>({option.asin || option.id}) </span>
            <span>{option.text}</span>
          </div>
        </button>
      );
    }

    return (
      <button
        key={i}
        onClick={(event) => {
          // if (
          //   (event.target as HTMLInputElement).matches(".ant-checkbox-input")
          // ) {
          //   return;
          // }
          if (isMultiSelect && Array.isArray(value)) {
            if (value.includes(option as string)) {
              onChange(value.filter((val) => val !== option));
            } else {
              onChange([...value, option]);
            }
          } else {
            onChange(option);
          }
        }}
      >
        {isMultiSelect && (
          <Checkbox checked={value.includes(option as string)} />
        )}
        {option}
      </button>
    );
  });
};

const renderValue = (value: string | string[], placeholder) => {
  if (Array.isArray(value) && value.length > 0) {
    return value.join(", ");
  }
  if (!Array.isArray(value) && value) {
    return value;
  }
  return placeholder;
};

interface SelectProps<T extends string | string[]> {
  value: T;
  placeholder: string | React.ReactNode;
  options: Option[] | string[] | React.ReactNode[];
  onChange: (value: T) => void;
  onOpen?: () => void;
  onClose?: () => void;
  isMultiSelect?: boolean;
  className?: string;
  children?: React.ReactNode;
}

export const Select = <T extends string | string[]>({
  value,
  placeholder,
  options,
  onChange,
  onOpen,
  onClose,
  isMultiSelect,
  className,
  children,
}: SelectProps<T>) => {
  const [open, setOpen] = useState<boolean>(false);
  const selectRef = useOutsideClick(() => {
    setOpen(false);
    onClose && onClose();
  });

  const handleChange = (value: T) => {
    onChange(value);

    if (Boolean(isMultiSelect) === false) {
      setTimeout(() => {
        setOpen(false);
      });
    }
  };

  return (
    <div
      className={classNames(styles.selector, className, open && styles.open)}
      ref={selectRef}
    >
      <button
        onClick={(event) => {
          console.log("click 2", event.target);
          setOpen(!open);
          if (!open) {
            onOpen && onOpen();
          }
        }}
        className={classNames(styles.value, !value && styles.placeholder)}
      >
        <input
          value={renderValue(value, placeholder)}
          readOnly
          placeholder={placeholder as string}
        />
        <span className={styles.arrow}>
          <BottomArrow />
        </span>
      </button>

      {open && (
        <div className={styles.options}>
          {children}
          {options.length ? (
            renderOptions(options, value, handleChange, isMultiSelect)
          ) : (
            <p>No options</p>
          )}
        </div>
      )}
    </div>
  );
};
