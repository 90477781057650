import React, { useState } from "react";
import ReactECharts from "echarts-for-react";
import styles from "./styles.module.scss";
import classNames from "classnames";
import { getOption, seriesConfig } from "./utils";
import { SanKey } from "@pages/AdsAnalitics/components/SanKey";
import { ArrowLeft } from "lucide-react";
import { useAdsAnaliticsStore } from "src/store/ads-analitics.store";

export const FunnelChart: React.FC = () => {
  const [openedDetails, setOpenedDetails] = useState("");
  const [activeSeries, setActiveSeries] = useState({
    "Amazon Ads": true,
    "Amazon Organic": true,
    "Organic Social": false,
    "Other Sales": false,
  });
  const { adSlides } = useAdsAnaliticsStore((state) => ({
    adSlides: state.adSlides,
  }));
  const toggleSeries = (seriesName: string) => {
    setActiveSeries((prev) => ({
      ...prev,
      [seriesName]: !prev[seriesName],
    }));
  };

  const handleFunnelClick = (params: any) => {
    if (params.componentType === "series") {
      setOpenedDetails(params.name);
    }
  };

  if (openedDetails) {
    return (
      <div className={styles.sankey}>
        <button
          className={styles.back}
          onClick={() => {
            setOpenedDetails("");
          }}
        >
          <ArrowLeft size={20} />
          <span>{openedDetails} Sankey</span>
        </button>
        <SanKey
          hideTree
          hideTitle
          hideAsin
          isFunnelChart
          defaultOpenTab="Funnel"
        />
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <h3>Funnel Performance</h3>
      <div className={styles.toggleButtons}>
        {seriesConfig.map((series, index) => (
          <button
            key={series.name}
            className={classNames(styles.toggleButton, {
              [styles.active]: activeSeries[series.name],
            })}
            style={{ backgroundColor: series.color }}
            onClick={() => toggleSeries(series.name)}
            disabled={index > 1 && !activeSeries[series.name]}
          >
            <span className={styles.indicator} />
            {series.name}
          </button>
        ))}
      </div>
      <div className={styles.chart__container}>
        <div className={styles.legend}>
          {seriesConfig.map((series, index) => {
            if (index < 2) {
              return (
                <div
                  key={series.name}
                  className={classNames(styles.legendItem)}
                  onClick={() => toggleSeries(series.name)}
                >
                  <span
                    className={styles.indicator}
                    style={{ backgroundColor: series.color }}
                  />
                  {series.name}
                </div>
              );
            }
          })}
        </div>
        <ReactECharts
          option={getOption(activeSeries, adSlides)}
          onEvents={{
            click: (params: any) => {
              handleFunnelClick(params);
            },
          }}
          style={{
            height: "538px",
            width: "1252px",
            left: "-54px",
          }}
        />
      </div>
    </div>
  );
};
